/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-25 15:54:53
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-03-25 15:57:45
 * @FilePath: /chat_gpt/src/store/getters.js
 */
const getters = {
  drawer: state => state.drawer == false
}
export default getters
